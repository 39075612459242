/* eslint-disable */
import Header from '../../Header.vue'
import Footer from '../../Footer.vue'
import NoData from '../../../components/NoData.vue'
import page from '../../../components/page.vue'
import axios from '../../../util/axios.js'
import CheckInService from '../../../api/CheckInService.js'
import '../../../util/tagcloud.js'
export default{
	name:'SearchResult',
	data(){
		return{
			curClassType:0,
			classTypeOptions:[],
			mainList:[],
			searchCon:{},
			pageIndex:1,
			pageSize:10,
			pageCount:0,
			searchWord:'',
			SearchWordsList:[],
			mediaFilePath:'',
			showPlayer:false,
			audioInfo:''
		}
	},
	methods:{
		GetSearchList(){ //获取搜索列表
			var that = this
			var resTypes = []
			resTypes.push(that.curClassType)
			CheckInService.GetEsSearch(that.pageIndex-1,that.pageSize,resTypes,that.searchWord.trim(),that.searchCon.searchCon,that.searchCon.startDate,that.searchCon.endDate).then(res=>{
				if(res){
					var result = JSON.parse(res)
					if(result.code == "1"){
						that.mainList = result.Data.DataList
						that.pageCount = result.Data.total
					}
				}
			})
		},
		pageChange(page){
			this.pageIndex = page
			this.GetSearchList()
			window.scrollTo(0,0)
		},
		toSearch(){ //二次检索
			this.pageIndex = 1
			this.pageCount = 0
			this.mainList = []
			this.GetSearchList()
		},
		GetSearchWords(){ //获取搜索词
			var that = this
			CheckInService.GetSearchWords(10).then(res=>{
				if(res.Tag == 1){
					that.SearchWordsList = res.Result
					setTimeout(function(){
						tagcloud({
							selector: ".zstp_box", //元素选择器
							fontsize: 18, //基本字体大小, 单位px
							radius: 70, //滚动半径, 单位px
							mspeed: "normal", //滚动最大速度, 取值: slow, normal(默认), fast
							ispeed: "normal", //滚动初速度, 取值: slow, normal(默认), fast
							direction: 60, //初始滚动方向, 取值角度(顺时针360): 0对应top, 90对应left, 135对应right-bottom(默认)...
							keep: false //鼠标移出组件后是否继续随鼠标滚动, 取值: false, true(默认) 对应 减速至初速度滚动, 随鼠标滚动
						});
					},10)
				}
			})
		},
		changeClass(type){
			this.curClassType = type
			this.pageIndex = 1
			this.pageCount = 0
			this.mainList = []
			this.GetSearchList()
		},
		toElementDetail(id){ //词条详情
			window.open(this.$WebUrl+'ElementDetail?id='+id)
		},
		toEntryDetail(id){ //条目详情
			window.open(this.$WebUrl+'EntryDetail?id='+id)
		},
		toBookDetail(id){ //图书详情
			window.open(this.$WebUrl+'BookDetail?id='+id)
		},
		toImageDetail(id){ //图片详情
			window.open(this.$WebUrl+'ImageDetail?id='+id)
		},
		playMedia(id){ //播放音视频
			var that = this
			CheckInService.GetMediaModel(id,that.$UserID).then(res=>{
				if(res.Tag == 1){
					that.audioInfo = res.Result.MediaModel
					that.mediaFilePath = res.Result.MediaModel.ResPath
					that.showPlayer = true
				}
			})
		},
		hidePlayer(){
			this.showPlayer = false
			this.$refs.player.pause()
			this.$refs.videoPlayer.pause()
		},
	},
	mounted(){
		var that = this
		that.searchCon = JSON.parse(that.$route.query.searchCon)
		console.log(that.searchCon)
		if(that.$route.query.searchType == 1){
			if(that.searchCon.resType.length > 0){
				that.classTypeOptions = that.searchCon.resType
				that.curClassType = that.classTypeOptions[0]
			}else{
				that.classTypeOptions = ['0','1','2','3','4','5']
				that.curClassType = that.classTypeOptions[0]
			}
			
		}else{
			that.classTypeOptions = ['0','1','2','3','4','5']
			that.curClassType = that.classTypeOptions[0]
			console.log(that.searchCon.searchCon[0])
			that.$refs.header.searchType = that.searchCon.searchCon[0].type
			that.$refs.header.keyWord = that.searchCon.searchCon[0].word
		}
		that.GetSearchList()
		that.GetSearchWords()
	},
	watch: {
		$route(to, from) {
			this.GetSearchList()
			this.GetSearchWords()
			window.location.reload()
		}
	},
	components:{
		'Header': Header,
		'Footer':Footer,
		'NoData':NoData,
		'page':page
	}
}